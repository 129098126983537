import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography, Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, Assessment, Settings as SettingsIcon, AccountCircle } from '@mui/icons-material';
import { BookTwoTone as BookTwoToneIcon, LockTwoTone as LockTwoToneIcon, Logout as LogoutIcon } from '@mui/icons-material';
import Swal from "sweetalert2";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const SettingsPage = () => {
  const [value, setValue] = useState(2); // Set value to 2 for the Settings tab
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Assuming user is authenticated initially
  const history = useHistory();

  const handleNavigation = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push('/landingpage');
        break;
      case 1:
        history.push('/Assessor2');
        break;
      case 2:
        history.push('/SettingsPage');
        break;
      case 3:
        history.push('/Profile2');
        break;
      default:
        break;
    }
  };

  const handleCardClick = (route) => {
    history.push(route);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    history.push("/Loginpage"); // Redirect to the login page
  };

  const handleChangePasswordClick = () => {
    history.push("/Changepassword");
  };
  
  const confirmLogout = async () => {
    const result = await Swal.fire({
      icon: "warning",
      title: "Apakah anda ingin keluar?",
      showCancelButton: true,
      confirmButtonColor: "#59c1bd",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya",
      cancelButtonText: "Tidak",
    });
  
    if (result.isConfirmed) {
      handleLogout(); // Call handleLogout if confirmed
    }
  };

  return (
    <Box className="min-h-screen bg-gray-100 flex flex-col pt-6 pb-6">
      <Box className="flex-grow overflow-auto w-full max-w-md mx-auto">
        <Card className="w-full p-4" style={{ backgroundColor: 'white' }}>
          <CardContent className="flex flex-col items-center">
            <Typography variant="h6" component="h1" className="mb-6"
              sx={{
                textAlign: 'center',
                mt: 2,
                fontFamily: 'Poppins, Arial, sans-serif',
                fontWeight: 'bold'
              }}>
              Settings
            </Typography>
            <Box className="w-full">
              <Card className="mb-6 mx-3 md:mx-0">
                <CardContent>
                  <Typography variant="h6" component="h2" className="text-blue-600 mb-4">
                    About Us
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'justify',
                      width: '100%',
                      mt: 2,
                      fontFamily: 'Poppins, Arial, sans-serif',
                    }}
                  >
                    ToCA (Tools of Culture Assessment) merupakan suatu aplikasi yang menyediakan beberapa tools untuk karyawan memberikan assessment atau feedback dan apresiasi kepada siapapun seperti superior, peer, subordinate, dan diri sendiri.
                  </Typography>
                </CardContent>
              </Card>
              <Typography variant="h6" component="h2" className="text-blue-600 mb-4">
                Other Settings
              </Typography>
              <Card className="mb-4 mx-3 md:mx-0 mt-6">
                <CardContent className="flex items-center justify-between">
                  <Box className="flex items-center">
                    <BookTwoToneIcon style={{ color: '#2F3A8F' }} />
                    <Typography variant="body1" className="ml-2">
                      Guides Book
                    </Typography>
                  </Box>
                  <KeyboardArrowRightIcon />
                </CardContent>
              </Card>
              <Card className="mb-4 mx-3 md:mx-0 mt-6 cursor-pointer" onClick={handleChangePasswordClick}>
                <CardContent className="flex items-center justify-between">
                  <Box className="flex items-center">
                    <LockTwoToneIcon style={{ color: '#2F3A8F' }} />
                    <Typography variant="body1" className="ml-2">
                      Change Password
                    </Typography>
                  </Box>
                  <KeyboardArrowRightIcon />
                </CardContent>
              </Card>
              <Card className="mb-4 mx-3 md:mx-0" onClick={confirmLogout}>
                <CardContent className="flex items-center justify-between">
                  <Box className="flex items-center">
                    <LogoutIcon style={{ color: '#2F3A8F' }} />
                    <Typography variant="body1" className="ml-2">
                      LogOut
                    </Typography>
                  </Box>
                  <KeyboardArrowRightIcon />
                </CardContent>
              </Card>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {/* Fixed width for BottomNavigation */}
      <Box
        className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto"
        style={{ width: '100%', maxWidth: '465px' }} // Set a fixed width
      >
        <BottomNavigation
          value={value}
          onChange={handleNavigation}
          showLabels
          sx={{ width: '100%' }} // Ensure BottomNavigation takes full width
        >
          <BottomNavigationAction 
            label="Home" 
            icon={<Home />} 
            sx={{ 
              color: value === 0 ? 'blue' : 'inherit', // Set color based on active state
              '& .MuiBottomNavigationAction-icon': { color: value === 0 ? 'blue' : 'inherit' } // Ensure icon color changes
            }}
          />
          <BottomNavigationAction 
            label="Assessment" 
            icon={<Assessment />} 
            sx={{ 
              color: value === 1 ? 'blue' : 'inherit', // Set color based on active state
              '& .MuiBottomNavigationAction-icon': { color: value === 1 ? 'blue' : 'inherit' } // Ensure icon color changes
            }}
          />
          <BottomNavigationAction 
            label="Settings" 
            icon={<SettingsIcon />} 
            sx={{ 
              color: value === 2 ? 'blue' : 'inherit', // Set color based on active state
              '& .MuiBottomNavigationAction-icon': { color: value === 2 ? 'blue' : 'inherit' } // Ensure icon color changes
            }}
          />
          <BottomNavigationAction 
            label="Profile" 
            icon={<AccountCircle />} 
            sx={{ 
              color: value === 3 ? 'blue' : 'inherit', // Set color based on active state
              '& .MuiBottomNavigationAction-icon': { color: value === 3 ? 'blue' : 'inherit' } // Ensure icon color changes
            }}
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
};

export default SettingsPage;
