import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  CardMedia
} from "@mui/material";
import {
  Home as HomeIcon,
  Assessment as AssessmentIcon,
  Settings as SettingsIcon,
  AccountCircle as AccountCircleIcon
} from "@mui/icons-material";
import { styled } from "@mui/system";
import pen from "../../assets/pen.png";
import peduliinsani_logo from "../../assets/peduliinsani_logo.png";
const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  maxWidth: "900px",
  margin: "0 auto",
  minHeight: "100vh",
  overflowY: "auto",
  padding: "1rem 1rem 120px",
  boxSizing: "border-box",
  [theme.breakpoints.down("sm")]: {
    padding: "0.5rem 1rem 120px",
  },
}));

const StyledDescription = styled(Typography)({
  marginTop: "1rem",
  fontSize: "1.125rem",
  textAlign: "center",
});

const StyledList = styled("ol")(({ theme }) => ({
  listStyleType: "decimal",
  paddingLeft: "1rem",
  marginTop: "1rem",
  textAlign: "justify",
  width: "100%", // Set to 100% to match the Bottom Navigation
  maxWidth: "480px", // Limit max width to maintain consistency
  boxSizing: "border-box", // Ensure padding is included in width calculation
}));

const StyledListItem = styled("li")(({ theme }) => ({
  marginTop: "0.5rem",
  textAlign: "justify",
  wordBreak: "break-word",
  fontSize: '1.25rem', // Font size adjusted to match BottomNavigation
  [theme.breakpoints.down("sm")]: {
    fontSize: '1rem', // Adjust font size for mobile
  },
}));

const EventDescription = () => {
  const [instructions, setInstructions] = useState([]);
  const [instructionsId, setInstructionsId] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(0); // State for BottomNavigation
  const history = useHistory();
  const type_ids = localStorage.getItem('type_id');
  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchInstructions = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        
        if (!token) {
          throw new Error("Access token not found in localStorage");
        }
    
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_assessment_type/${user_id}/${type_ids}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        const assessmentTypes = response.data.assessment_type;
        const instructionsList = assessmentTypes.flatMap(type => type.instructions.split('\n').filter(instruction => instruction.trim() !== ""));
        const instructionsId = assessmentTypes.map(type => type.type_id);
        
        setInstructionsId(instructionsId[0]);
        setInstructions(instructionsList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching instructions:", error);
        setError("An error occurred while fetching instructions. Please try again later.");
        setLoading(false);
      }
    };

    fetchInstructions();
  }, [type_ids]);

  const handleNavigation = (event, newValue) => {
    setValue(newValue); // Update value when tab changes
    switch (newValue) {
      case 0:
        history.push('/home');
        break;
      case 1:
        history.push('/assessment');
        break;
      case 2:
        history.push('/settings');
        break;
      case 3:
        history.push('/profile');
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    console.log("instruction id", instructionsId);
    history.push('/PeduliInsaniQuestion', { state: { instructionsId } });
  };

  return (
    <div className="komponen-instruksi">
      <StyledContainer>
        <CardMedia
          component="img"
          image={peduliinsani_logo}
          alt="peduliinsani_logo"
          style={{ 
            width: '210px', // Adjust as needed
            height: 'auto', // Maintain aspect ratio
            marginTop: '10%'
          }}
        />

        <CardMedia
          component="img"
          image={pen}
          alt="pen"
          style={{ width: '150px', height: '150px', marginTop: '10%' }} // Adjust width and height
        />
        
        <StyledDescription variant="h8" style={{ fontWeight:'bold' }}>
          INSTRUKSI MENGERJAKAN
        </StyledDescription>
        
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography variant="body1" color="error">{error}</Typography>
        ) : (
          <StyledList>
            {instructions.map((instruction, index) => (
              <StyledListItem key={index}>
                {instruction}
              </StyledListItem>
            ))}
          </StyledList>
        )}
        
        <div className="w-full max-w-md flex justify-center mt-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5"
            style={{ width: '100%' }}
            onClick={handleClick}
          >
            Start Assessment
          </button>
        </div>
        
        <Box 
          className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white" 
          style={{ 
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", 
            zIndex: 1000,
          }}>
          <BottomNavigation
            value={value}
            onChange={handleNavigation}
            showLabels
          >
            <BottomNavigationAction
              label="Home"
              icon={<HomeIcon />}
              onClick={() => history.push("/Landingpage")}
            />
            <BottomNavigationAction
              label="Assessment"
              icon={<AssessmentIcon />}
              onClick={() => history.push("/TotalAssessmentNew")}
            />
            <BottomNavigationAction
              label="Settings"
              icon={<SettingsIcon />}
              onClick={() => history.push("/settingsPage")}
            />
            <BottomNavigationAction
              label="Profile"
              icon={<AccountCircleIcon />}
              onClick={() => history.push("/Profile2")}
            />
          </BottomNavigation>
        </Box>


      </StyledContainer>
    </div>
  );
};

export default EventDescription;
