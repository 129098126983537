import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  Person as PersonIcon,
  Smartphone as SmartphoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  LocationCity as LocationCityIcon,
  CameraAlt as CameraAltIcon,
} from "@mui/icons-material";
import {
  Box,
  Card,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import axios from "axios";
import writing from "../../assets/writing.png";
import Swal from "sweetalert2";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";
import { useAuth } from "../AuthContext/AuthContext";

const Profile = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const [employeesData, setEmployeesData] = useState([]);
  const [averageScore, setAverageScore] = useState(0);
  const userId = localStorage.getItem("user_id");
  const token = localStorage.getItem("accessToken");
  const [profileImageBinary, setProfileImageBinary] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [photoChanged, setPhotoChanged] = useState(false);
  const fileInputRef = useRef(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (userId && token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/get_data_user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const profileImageBinary =
            response.data.employee_data[0].profile_image_binary;
          setEmployeesData(response.data.employee_data);
          console.log("response.data.employee_data",response.data.employee_data)

          if (profileImageBinary) {
            setProfileImageBinary(profileImageBinary);
          }

          // Fetch average scores
          axios
            .get(`${process.env.REACT_APP_API_URL}/get_score/${userId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((scoreResponse) => {
              setAverageScore(scoreResponse.data.overall_average);
              localStorage.setItem(
                "roundedAverageScore",
                scoreResponse.data.overall_average.toFixed(2)
              );
            })
            .catch((error) => {
              console.error("Error fetching average scores:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [userId, isAuthenticated, history, token]);

  const [activeTab, setActiveTab] = useState(0);

  const entitas = employeesData.map((employee) => employee.entitas);
  localStorage.setItem("entitas", entitas);

  const handleClick = () => {
    history.push("/Homepage");
  };

  const roundedAverageScore = averageScore.toFixed(2);
  localStorage.setItem("roundedAverageScore", roundedAverageScore);

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        history.push("/Landingpage");
        break;
      case 1:
        history.push("/TotalAssessmentNew");
        break;
      case 2:
        history.push("/SettingsPage");
        break;
      case 3:
        history.push("/Profile2");
        break;
      case 4:
        history.push("/Personalscore2");
        break;
      case 5:
        history.push("/Assessor2");
        break;
      case 6:
        history.push("/Feedback2");
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const previewImageUrl = URL.createObjectURL(selectedFile);
      setPreviewImage(previewImageUrl);
      setFileInput(selectedFile);
      setPhotoChanged(true);
    }
  };

  const handleUpdateProfile = () => {
    if (fileInput) {
      const formData = new FormData();
      formData.append("profile_image", fileInput);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/update_profile_image/${userId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.error) {
            console.error("Error updating profile image:", response.data.error);
          } else {
            Swal.fire({
              icon: "success",
              title: "Upload foto berhasil",
              confirmButtonColor: "#59c1bd",
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error updating profile image:", error);
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Tidak ada file",
        text: "Silahkan pilih file!",
        confirmButtonColor: "#59c1bd",
      });
    }
  };

  const handleProfileClick = () => {
    if (photoChanged) {
      handleUpdateProfile();
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center py-8">
      <Card
        style={{ height: "20rem" }}
        className="w-full max-w-md bg-white rounded-lg shadow-md p-4 mb-6 mx-4 sm:mx-6 md:mx-8 lg:mx-12"
      >
        <div className="flex justify-between items-center mb-4">
          <div className="flex-grow flex justify-center">
            <Typography variant="h7" className="text-lg font-bold">
              Profile
            </Typography>
          </div>
        </div>
        <div className="flex justify-center mb-4">
          <div className="relative">
            {previewImage ? (
              <img
                src={previewImage}
                alt="Preview"
                className="rounded-full w-24 h-24 object-cover"
              />
            ) : profileImageBinary ? (
              <img
                src={`data:image/png;base64,${profileImageBinary}`}
                alt="Profile"
                className="rounded-full w-28 h-28 object-cover"
                onError={(e) => console.error("Error loading profile image:", e)}
              />
            ) : (
              <div className="w-28 h-28 bg-gray-300 rounded-full flex items-center justify-center">
                <Typography className="text-white text-lg">No Photo</Typography>
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="flex justify-center mb-4">
          <button
            onClick={handleProfileClick}
            className="text-blue-500 hover:underline"
          >
            {photoChanged ? "Save Image" : "Change Photo"}
          </button>
        </div>
        {employeesData.map((employee, index) => (
          <div key={index} className="text-center mb-4">
            <Typography style={{ fontSize: "1rem" }} className="font-semibold">
              {employee.name}
            </Typography>
            <Typography
              style={{ fontSize: "0.8rem" }}
              className="text-gray-600"
            >
              {employee.position}
            </Typography>
            <div className="flex items-center justify-center mt-2">
              <img
                src={writing}
                alt="My Icon"
                style={{ width: "20px", height: "20px" }}
              />
              <Typography
                style={{ fontSize: "0.9rem"}}
                className="text-gray-600"
              >
                {roundedAverageScore}
              </Typography>
            </div>
          </div>
        ))}
      </Card>

      {employeesData.map((employee, index) => (
        <Card
          key={index}
          className="w-full max-w-md bg-white rounded-lg shadow-md p-6 mb-6 mx-4 sm:mx-6 md:mx-8 lg:mx-12"
        >
          <Typography variant="h7" className="font-bold mb-2">
            Informasi Pribadi
          </Typography>
          <div className="flex items-center mb-4 mt-5">
            <PersonIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography
                style={{ fontSize: "1rem" }}
                className="font-semibold"
              >
                NRP
              </Typography>
              <Typography className="text-gray-600">{employee.nrp}</Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <LocationCityIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Entitas</Typography>
              <Typography className="text-gray-600">
                {employee.entitas}
              </Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <LocationOnIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Alamat</Typography>
              <Typography className="text-gray-600">
                {employee.location}
              </Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <SmartphoneIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">No HP</Typography>
              <Typography className="text-gray-600">
                {employee.work_phone}
              </Typography>
            </div>
          </div>
          <div className="flex items-center mb-4">
            <EmailIcon className="text-gray-600" />
            <div className="ml-4">
              <Typography className="font-semibold">Email</Typography>
              <Typography className="text-gray-600">{employee.work_email}</Typography>
            </div>
          </div>
        </Card>
      ))}

<Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto">
        <BottomNavigation
          value={value}
          onChange={handleNavigation} 
          showLabels
          sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            sx={{
              "& .MuiBottomNavigationAction-icon": { color: "inherit" },
              "& .Mui-selected": { color: "inherit" }, // Ensures Home button does not get active color
            }}
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            sx={{
              color: activeTab === 1 ? "blue" : "inherit",
              "& .MuiBottomNavigationAction-icon": {
                color: activeTab === 1 ? "blue" : "inherit",
              },
            }}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            sx={{
              color: activeTab === 2 ? "blue" : "inherit",
              "& .MuiBottomNavigationAction-icon": {
                color: activeTab === 2 ? "blue" : "inherit",
              },
            }}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            sx={{
              color: activeTab === 3 ? "blue" : "inherit",
              "& .MuiBottomNavigationAction-icon": {
                color: activeTab === 3 ? "blue" : "inherit",
              },
            }}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default Profile;
