
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import BoyIcon from "@mui/icons-material/Boy";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";
import "./MandatoryAssessment.css";
import axios from "axios";

const Menu = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [assessmentData, setAssessmentData] = useState({});
 
  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("accessToken");

    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/mandatory_assessment/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setAssessmentData(response.data.assessment_counts);
      })
      .catch(error => {
        console.error('Error fetching assessment data:', error);
      });
    } else {
      console.error('No access token found in local storage.');
    }
  }, []);

  const handleClick = () => {
    history.push("/HomePage");
  };

  const handleNavigation = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push("/HomePage");
        break;
      case 1:
        history.push("/Assessment");
        break;
      case 2:
        history.push("/Settings");
        break;
      case 3:
        history.push("/Profile");
        break;
      default:
        break;
    }
  };

  return (
    <div className="logo-menu" style={{ overflow: "auto", maxHeight: "90vh", padding: "20px" }}>
      <div>
        <div className="back-icon-dashboard">
          <ArrowBackIcon onClick={handleClick} />
        </div>
        <div className="flex items-center justify-center">
          <img className="mr-2 toca-logo-menu" src={logo} alt="Logo" />
          <p className="text-welcome">Mandatory Assessment</p>
        </div>

        <div style={{ marginTop: "2%" }}>
          {Object.keys(assessmentData).map((assessmentTypeId) => {
            const assessment = assessmentData[assessmentTypeId];
            let IconComponent;
            let buttonClass;
            let text;

            switch (assessmentTypeId) {
              case '1':
                IconComponent = BoyIcon;
                buttonClass = 'btn-chart';
                text = "Leader";
                break;
              case '2':
                IconComponent = GroupIcon;
                buttonClass = 'btn-assessor';
                text = "Partner";
                break;
              case '3':
                IconComponent = SupervisedUserCircleIcon;
                buttonClass = 'btn-feedback';
                text = "Subordinates";
                break;
              case '4':
                IconComponent = PersonIcon;
                buttonClass = 'btn-mandatory';
                text = "Self Assessment";
                break;
              default:
                IconComponent = null;
                buttonClass = 'btn-unknown';
                text = "Unknown";
                break;
            }

            return (
              <div key={assessmentTypeId} className="w-full max-w-md mx-auto mb-2">
                <div className={`button-${buttonClass}`}>
                  <button type="button" className={`${buttonClass} w-full`}>
                    <div className="button-content flex items-center justify-center">
                      {IconComponent && <IconComponent className="grafik" />}
                      <span className="chart-text">{text}</span>
                      <span className="chart-count">{assessment.count}</span>
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white" style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <BottomNavigation
          value={value}
          onChange={handleNavigation}
          showLabels
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            onClick={() => history.push("/Landingpage")}
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            onClick={() => history.push("/TotalAssessmentNew")}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            onClick={() => history.push("/settingsPage")}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            onClick={() => history.push("/Profile2")}
          />
        </BottomNavigation>
      </Box>
    </div>
  );
};

export default Menu;