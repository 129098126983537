import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip
} from "recharts";
import {
  Card,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@mui/material";

export default function App() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    const token = localStorage.getItem("accessToken");

    if (!userId || !token) {
      console.error("User ID or access token is not defined in localStorage");
      return;
    }

    axios.get(`${process.env.REACT_APP_API_URL}/get_score/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      const averageScores = response.data.average_scores || {};
      const coreValuesNames = response.data.core_values_names || {};

      const chartData = Object.keys(averageScores).map(coreValuesId => ({
        subject: coreValuesNames[coreValuesId] || `Core Value ${coreValuesId}`,
        A: averageScores[coreValuesId] || 0,
        fullMark: 100 // Set fullMark to 100 for the scale
      }));

      setData(chartData);
    })
    .catch(error => console.error("Error fetching data:", error));
  }, []);

  const styles = {
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center"
    },
    chart: {
      fontSize: "10px",
      padding: "0",
      margin: "0",
      display: "flex",
      justifyContent: "center",
      marginLeft: "2%",
      marginTop:"-10%,"
    },
    tableHead: {
      backgroundColor: "#2F3A8F"
    },
    card: {
      width: "90%",
      maxWidth: 410,
      height: 250, // Adjust card width
      margin: "20px auto",
      boxShadow: "0 10px 10px rgba(0,0,0,0.1)",
      borderRadius: "16px", 
    }
  };

  return (
    <div>
      <div style={styles.container}>
        <Card style={styles.card}>
          <CardContent>
            <div style={styles.chart}>
              <RadarChart
                cx="50%"
                cy="50%"
                outerRadius={60} // Adjust outer radius to fit better
                width={350} // Adjust chart width
                height={250} // Adjust chart height
                data={data}
              >
                <PolarGrid />
                <PolarAngleAxis 
                  dataKey="subject"
                  tick={{ fontSize: 8 }} // Further reduce font size if needed
                  tickLine={false} // Remove tick line for a cleaner look
                />
                <PolarRadiusAxis 
                  angle={30} // Adjust angle for better text placement
                  tickCount={6} // Define the number of ticks (0, 20, 40, 60, 80, 100)
                  domain={[0, 100]} // Ensure the domain is within 0-100
                  tick={{ fontSize: 8 }} // Adjust the font size of the ticks
                />
                <Radar
                  name="Average Score"
                  dataKey="A"
                  stroke="#2F3A8F"
                  fill="#2F3A8F"
                  fillOpacity={0.6}
                />
                <Tooltip />
              </RadarChart>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="center" style={{ marginTop: '5%' }}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead style={styles.tableHead}>
                <TableRow>
                  <TableCell style={{ color: "white" }}>Core Values</TableCell>
                  <TableCell style={{ color: "white" }}>Average Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.subject}>
                    <TableCell>{item.subject}</TableCell>
                    <TableCell>{item.A.toFixed(1)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
}
