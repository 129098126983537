import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import ykbutBg from '../../assets/background.png';

const AssessmentForm = () => {
  const history = useHistory();
  const [formData, setFormData] = useState([]);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [nrp, setNrp] = useState('');
  const [entitas, setEntitas] = useState('');
  const [entitas_id, setEntitasId] = useState('');
  const [mData, setMData] = useState([]);
  const [modul, setModul] = useState('');
  const [modul_id, setModul_id] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState('');
  const [type_id, setType_id] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const user_id = localStorage.getItem("user_id")
  const currentUserId = localStorage.getItem('employee_id'); // Assume this is set elsewhere in your application

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_forms/${user_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        const data = response.data;
        console.log('Fetched data:', data);
        setMData(data.module || []);
        setTypeOptions(data.type || []);
        setFormData(data.employee || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (type) {
      const selectedType = typeOptions.find(t => t.name === type);
      const selectedTypeId = selectedType ? selectedType.id : null;

      let filteredEmployees = [];
      if (selectedTypeId === 4) { // Assuming ID 4 means "Self Assessment"
        filteredEmployees = formData.filter(emp => emp.id === parseInt(currentUserId));
      } else {
        filteredEmployees = formData;
      }

      console.log('Filtered Employees:', filteredEmployees);
      setFilteredData(filteredEmployees);

      setType_id(selectedTypeId);
    } else {
      setFilteredData(formData);
    }
  }, [type, formData, typeOptions, currentUserId]);

  const handleClick = () => {
    history.push("/TotalAssessmentNew");
  };

  const handleNextButton = () => {
    if (!name) {
      alert("Please select an employee.");
      return;
    }

    if (!type) {
      alert("Please select an assessment type.");
      return;
    }

    if (!entitas || !entitas_id || !id || !modul || !modul_id) {
      alert("Please complete all required fields.");
      return;
    }

    const isValidType = typeOptions.some((item) => item.name === type);
    if (isValidType) {
      localStorage.setItem('modul_id', modul_id);
      localStorage.setItem('id', id);
      localStorage.setItem('entitas', entitas);
      localStorage.setItem('entitas_id', entitas_id);
      localStorage.setItem('type_id', type_id);
      localStorage.setItem('type', type);

      history.push('/Instruksi', { state: { type_id } }); 
    } else {
      console.error("Unhandled assessment type:", type);
      alert("Selected assessment type is not valid.");
    }
  };

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${ykbutBg})` }}>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4 md:p-6">
        <div className="w-full max-w-full md:max-w-md p-6 bg-white rounded-lg shadow-lg mx-2 md:mx-4 overflow-y-auto">
          <div className="flex items-center mb-4">
            <ArrowBackIcon onClick={handleClick} className="cursor-pointer" />
            <h1 className="flex-1 text-xl font-bold text-center">Assessment Form</h1>
          </div>
          <form className="flex flex-col space-y-4">
            <label htmlFor="assessmentType" className="block text-sm mb-2">
              Assessment Type
              <select
                id="assessmentType"
                className="block w-full px-3 py-2 mt-1 mb-4 border border-gray-300 rounded-md"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled hidden>Select Assessment Type</option>
                {typeOptions.map((typeOption, index) => (
                  <option key={index} value={typeOption.name} disabled={typeOption.name === 'Leader' || typeOption.name === 'Partner' || typeOption.name === 'SubOr' } style={typeOption.name === 'Leader' || typeOption.name === 'Partner' || typeOption.name === 'SubOr'  ? { color: 'gray' } : {}}>
                    {typeOption.name}
                  </option>
                ))}
              </select>
            </label>

            {filteredData.length > 0 && (
              <>
                <label htmlFor="name" className="block text-sm mb-2">
                  Employee Name / Nrp
                  <input
                    type="text"
                    list="names"
                    id="name"
                    className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNrp('');
                      setEntitas('');
                    }}
                    onBlur={() => {
                      const selectedForm = filteredData.find((form) => form.name === name);
                      if (selectedForm) {
                        setNrp(selectedForm.nrp);
                        setEntitas(selectedForm.entitas);
                        setEntitasId(selectedForm.entitas_id);
                        setId(selectedForm.id);
                      }
                    }}
                    placeholder='Name'
                  />
                </label>
                <datalist id="names">
                  <option value="" disabled hidden>Name</option>
                  {filteredData.map((form, index) => (
                    <option key={index} value={form.name}>
                      {form.name} ({form.nrp})
                    </option>
                  ))}
                </datalist>
                <label htmlFor="entitas" className="block text-sm mb-2">
                  Entitas
                  <input type="text" id="entitas" className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md" value={entitas} readOnly />
                </label>
              </>
            )}

            <label htmlFor="modul" className="block text-sm mb-2">
              Culture Module
              <select
                id="modul"
                className="block w-full px-3 py-2 mt-1 mb-4 border border-gray-300 rounded-md"
                value={modul}
                onChange={(e) => {
                  setModul(e.target.value);
                  setModul_id('');
                }}
                onBlur={() => {
                  const selectedForm = mData.find((module) => module.name === modul);
                  if (selectedForm) {
                    setModul_id(selectedForm.id);
                  }
                }}
              >
                <option value="" disabled hidden>Culture module</option>
                {mData.map((module, index) => (
                  <option key={index} value={module.name} disabled={module.name === 'PANCADAYA'} style={module.name === 'PANCADAYA' ? { color: 'gray' } : {}}>
                    {module.name}
                  </option>
                ))}
              </select>
            </label>
            <button type="button" className="block w-full px-4 py-2 mt-4 text-white rounded-md hover:bg-blue-600" style={{ backgroundColor: '#38419D' }} onClick={handleNextButton}>
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssessmentForm;
