import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import peduliinsani from "../../assets/peduliinsani_logo.png";
import ykbutnew from "../../assets/ykbutnew.png";
import "./Partnership.css";

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const PartnershipQuestion1 = ({ assessment_type_id }) => {
  const location = useLocation();
  const id_questions = location.state.state;
  const [questionLine, setQuestionLine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(10);
  const [values, setValues] = useState({});
  const [instruksiMengerjakan, setInstruksiMengerjakan] = useState(""); // Define state for instruksiMengerjakan
  const cardRef = useRef(null);
  const [isInteracting, setIsInteracting] = useState(false);
  const progressBarRef = useRef(null);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const history = useHistory();
  const userId = localStorage.getItem("user_id");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/get_question/${userId}/${
            id_questions.instructionsId || null
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
  
        // Sort questions based on reverse flag
        const sortedQuestions = data.question_line.map((question) => {
          const sortedAnswerLine = question.reverse
            ? [...question.answer_line].sort((a, b) => b.order - a.order) // Reverse order
            : [...question.answer_line].sort((a, b) => a.order - b.order); // Normal order
          return { ...question, answer_line: sortedAnswerLine };
        });
  
        // Shuffle the questions
        const shuffledQuestions = shuffle(sortedQuestions);
  
        setQuestionLine(shuffledQuestions);
        if (shuffledQuestions.length > 0) {
          setInstruksiMengerjakan(shuffledQuestions[0].intruksisoal_field || "");
        }
        setLoading(false);
      } catch (error) {
        // console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [assessment_type_id]);
  

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setProgress(10);
  }, [currentQuestionIndex]);

  const handleTriggerClick = () => {
    // console.log("Trigger Clicked");
  };

  const handleNextButton = async () => {
  const currentQuestion = questionLine[currentQuestionIndex];
  const adjustedProgress = currentQuestion.reverse ? 110 - progress : progress;

  setValues((prevValues) => ({
    ...prevValues,
    [currentQuestion.id]: adjustedProgress,
  }));

  if (currentQuestionIndex < questionLine.length - 1) {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setProgress(10);
    setIndicatorPosition(0);
    setInstruksiMengerjakan(
      questionLine[currentQuestionIndex + 1].intruksisoal_field || ""
    );
  } else {
    try {
      const lastQuestion = questionLine[currentQuestionIndex];
      const finalAdjustedProgress = lastQuestion.reverse ? 110 - progress : progress;

      const updatedValues = {
        ...values,
        [lastQuestion.id]: finalAdjustedProgress,
      };

      const token = localStorage.getItem("accessToken");
      const employeeId = localStorage.getItem("id");
      const penilaiId = localStorage.getItem("employee_id");
      const entitasId = localStorage.getItem("entitas_id");
      const modulId = localStorage.getItem("modul_id");

      const groupedAssessmentData = questionLine.reduce((acc, question) => {
        const { core_values_id, id } = question;
        if (!acc[core_values_id]) {
          acc[core_values_id] = {
            core_values_id,
            assessment_question_line: [],
          };
        }
        acc[core_values_id].assessment_question_line.push({
          question_line_id: id,
          score: updatedValues[id] || 0,
        });
        return acc;
      }, {});

      const assessmentData = Object.values(groupedAssessmentData);
      const user_id = localStorage.getItem("user_id");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/post/${user_id}`,
        {
          employee_id: Number(employeeId),
          modul: Number(modulId),
          penilai: Number(penilaiId),
          entitas: Number(entitasId),
          assessment_type_id: Number(id_questions.instructionsId),
          assessment_line: assessmentData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        title: "Assessment Selesai",
        text: "Terima kasih sudah mengisi assessment.",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/Landingpage");
        }
      });

      setQuestionLine([]);
      setValues({});
      setCurrentQuestionIndex(0);
    } catch (error) {
      // console.error("Error submitting data:", error);
      Swal.fire({
        title: "Error",
        text: "Erro saat submit assessment.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }
};


  const displayProgress = (progress) => {
    if (progress === 10) {
      return 0;
    }
    return progress;
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setInstruksiMengerjakan(
        questionLine[currentQuestionIndex - 1]?.intruksisoal_field || ""
      );
    }
  };

  const handleDrag = (clientX) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const relativePosition = ((clientX - rect.left) / rect.width) * 100;

    let newProgress;

    if (relativePosition <= 10) {
      newProgress = 10;
    } else if (relativePosition > 10 && relativePosition <= 48) {
      newProgress = Math.floor(
        ((relativePosition - 10) / (48 - 10)) * (50 - 10) + 10
      );
    } else if (relativePosition > 48 && relativePosition <= 93) {
      newProgress = Math.floor(
        ((relativePosition - 48) / (93 - 48)) * (100 - 50) + 50
      );
    } else {
      newProgress = 100;
    }

    setProgress(Math.min(Math.max(newProgress, 10), 100));
    setIndicatorPosition(clientX - rect.left);
  };

  const handleMouseDown = (event) => {
    setIsInteracting(true); // Lock scrolling
    handleDrag(event.clientX);
  
    const handleMouseMove = (event) => {
      handleDrag(event.clientX);
    };
  
    const handleMouseUp = () => {
      setIsInteracting(false); // Unlock scrolling
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  
  const handleTouchStart = (event) => {
    setIsInteracting(true); // Lock scrolling
    handleDrag(event.touches[0].clientX);
  
    const handleTouchMove = (event) => {
      handleDrag(event.touches[0].clientX);
    };
  
    const handleTouchEnd = () => {
      setIsInteracting(false); // Unlock scrolling
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  };
  useEffect(() => {
    if (isInteracting) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isInteracting]);  

  if (loading) {
    return <div>Loading...</div>;
  }

  const currentQuestion = questionLine[currentQuestionIndex];
  // console.log("currentQuestion:", currentQuestion);
  // console.log("progress:", progress);
  // console.log("values:", values);

  return (
    <div className="space">
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
        <Card className="w-full max-w-lg" ref={cardRef}>
          <CardContent
            className="flex items-center justify-center w-full max-w-[680px] h-[230px]"
            style={{
              backgroundImage: `url(${ykbutnew})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="logo-peduliinsani-new">
              <img
                className="icon-peduliinsani-new h-12 w-auto -mt-12"
                src={peduliinsani}
                alt="Peduli Insani Logo"
              />
            </div>
          </CardContent>
        </Card>

        <div className="w-full max-w-lg -mt-20">
          <Card className="bg-white w-full max-w-[640px] h-auto shadow-lg">
            <CardContent className="p-6">
              {questionLine.length > 0 ? (
                <React.Fragment>
                  <div className="mb-2 font-bold mb-4 text-gray-700 text-justify">
                    Pertanyaan {currentQuestionIndex + 1}
                  </div>
                  <div className="mb-6 text-sm font-bold text-gray-900 text-justify">
                    {currentQuestion?.name || "No question available"}
                  </div>

                  <div
                    className="quest-component text-sm mb-4 font-bold mb-4 mt-1"
                    style={{ color: "#7D7D7D", fontSize: "0.8rem" }} // Adjust the font size here
                  >
                    Cara mengerjakan
                  </div>
                  <div
                    className="instruksi-soal text-sm mb-4 font-bold mb-4 "
                    style={{ fontSize: "0.8rem" }} // Adjust the font size here
                  >
                    {instruksiMengerjakan || "Instruksi tidak tersedia"}
                  </div>

                  <div className="mb-2">
                    <div
                      className="quest-component text-sm mb-4 font-bold mb-4 mt-6"
                      style={{ color: "#7D7D7D" }}
                    >
                      Jawaban
                    </div>
                    <ul className="answer-list text-justify">
                      {currentQuestion.answer_line?.map((item, index) => {
                        const circleClasses = [
                          "circle-A",
                          "circle-B",
                          "circle-C",
                        ];
                        const circleClass =
                          circleClasses[index] || "circle-default";

                        return (
                          <li
                            key={index}
                            className="text-sm mb-2 flex items-center"
                          >
                            <div
                              className={`circle-indicator ${circleClass}`}
                            />
                            <div className="ml-2">{item.answer}</div>
                          </li>
                        );
                      }) || <li>No answers available</li>}
                    </ul>
                  </div>
                </React.Fragment>
              ) : (
                <div>No questions available</div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="progressbar bg-gray-100 ">
        <div
          className="progress-bar-container relative mt-6 sm:mt-8 md:mt-25"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          ref={progressBarRef}
          style={{
            height: "20px",
            background: `linear-gradient(to right, #C9A2C9, #975C8D, #E36BAE)`,
            borderRadius: "10px",
            position: "relative",
            display: "flex",
            alignItems: "center", // Align markers vertically
          }}
        >
          <div
            className="progress-bar"
            style={{
              width: `${progress}%`,
              background: `linear-gradient(to right, rgba(201, 162, 201, 0) 0%, rgba(201, 162, 201, 0) ${progress}%, rgba(201, 162, 201, 0) ${progress}%, rgba(201, 162, 201, 0) 100%)`,
              borderRadius: "10px",
              transition: "width 0.3s ease",
              height: "100%", // Make sure the progress bar height matches the container
            }}
          />

          {/* Main markers */}
          <div
            className="marker"
            style={{
              left: "0%",
              backgroundColor: "#C9A2C9",
              border: "2px solid white", // Black outline
            }}
          ></div>
          <div
            className="marker"
            style={{
              left: "46.7%",
              backgroundColor: "#975C8D",
              border: "2px solid white", // Black outline
            }}
          ></div>
          <div
            className="marker"
            style={{
              left: "92.7%",
              backgroundColor: "#E36BAE",
              border: "2px solid white", // Black ou  tline
            }}
          ></div>

          {/* Markers between A-B */}
          <div
            className="marker small"
            style={{ left: "17%", backgroundColor: "#ccc" }}
          ></div>
          <div
            className="marker small middle"
            style={{ left: "28%", backgroundColor: "#ccc" }}
          ></div>
          <div
            className="marker small"
            style={{ left: "38%", backgroundColor: "#ccc" }}
          ></div>

          {/* Markers between B-C */}
          <div
            className="marker small"
            style={{ left: "63.2%", backgroundColor: "#ccc" }}
          ></div>
          <div
            className="marker small middle"
            style={{ left: "73%", backgroundColor: "#ccc" }}
          ></div>
          <div
            className="marker small"
            style={{ left: "83%", backgroundColor: "#ccc" }}
          ></div>

          {/* Circular marker */}
          <div
            className="progress-bar-indicator"
            style={{
              left: `calc(${progress}% - 10px)`, // Center the marker on the progress
              top: "50%", // Vertically center the marker
              width: "21px",
              height: "21px",
              borderRadius: "50%",
              backgroundColor: "#ffffff",
              cursor: "pointer",
              position: "absolute",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        <div className="btn-page1 bg-gray-100">
          <button
            type="button"
            className="btn-feedback-form"
            onClick={handleBack}
            disabled={currentQuestionIndex === 0}
          >
            Back
          </button>
          <button
            type="button"
            className="btn-feedback-form"
            onClick={handleNextButton}
          >
            {currentQuestionIndex < questionLine.length - 1 ? "Next" : "Submit"}
          </button>
        </div>
      </div>    
     
    </div>
  );
};

export default PartnershipQuestion1;
