import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Avatar,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DashboardIcon from "@mui/icons-material/Dashboard";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import merdeka from "../../assets/merdeka.jpg";
import bergerak from "../../assets/bergerak.jpg";
import backdropnew from "../../assets/backdropnew.png";
import soft from "../../assets/soft.png";
import tocatim from "../../assets/tocatim.jpg";
import pod1 from "../../assets/pod1.jpg";
import pod2 from "../../assets/pod2.jpg";
import pod3 from "../../assets/pod3.jpg";

const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  margin: "10px 0",
  height: "auto",
  cursor: "pointer",
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  width: 140,
  height: 110,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "10px",
  width: "100%",
  height: "100%",
  overflowY: "auto",
}));

const CarouselImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "200px",
  borderRadius: "8px",
  overflow: "hidden",
}));

const HomePage = () => {
  const [value, setValue] = useState(0);
  const [user, setUser] = useState({ name: "", avatar: "" });
  const [employeesData, setEmployeesData] = useState([]);
  const [profileImageBinary, setProfileImageBinary] = useState("");
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("accessToken");
    const popupShownKey = `popup_shown_${userId}`;

    if (userId && token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/get_data_user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "json",
        })
        .then((response) => {
          const profileImageBinary =
            response.data.employee_data[0].profile_image_binary;
          setEmployeesData(response.data.employee_data);
          localStorage.setItem("profile_image_url", profileImageBinary);

          if (profileImageBinary) {
            setProfileImageBinary(profileImageBinary);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (!localStorage.getItem(popupShownKey)) {
      Swal.fire({
        title: "Change Password",
        text: "Ganti Password untuk keamanan akun anda",
        icon: "warning",
        confirmButtonText: "Ubah",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCloseButton: false,
      }).then(() => {
        localStorage.setItem(popupShownKey, "true"); // Set the flag so the alert won't show again for this user
        history.push("/ChangePassword"); // Redirect after confirming
      });
    }
  }, [history]);



  useEffect(() => {
    const user_name = localStorage.getItem("user_name");
    const user_avatar = localStorage.getItem("user_avatar");
    setUser({ name: user_name, avatar: user_avatar });

    switch (location.pathname) {
      case "/Landingpage":
        setValue(0);
        break;
      case "/TotalAssessment":
        setValue(1);
        break;
      case "/SettingsPage":
        setValue(2);
        break;
      case "/Profile2":
        setValue(3);
        break;
      case "/Personalscore2":
        setValue(4);
        break;
      case "/Assessor2":
        setValue(5);
        break;
      case "/Feedback2":
        setValue(6);
        break;
      case "/Mandatory":
        setValue(7);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        history.push("/Landingpage");
        break;
      case 1:
        history.push("/TotalAssessmentNew");
        break;
      case 2:
        history.push("/SettingsPage");
        break;
      case 3:
        history.push("/Profile2");
        break;
      case 4:
        history.push("/Personalscore2");
        break;
      case 5:
        history.push("/Assessor2");
        break;
      case 6:
        history.push("/Feedback2");
        break;
      case 7:
        history.push("/Mandatory");
        break;
      default:
        break;
    }
  };

  const handleCardClick = (page) => {
    history.push(page);
  };

  return (
    <Box className="flex flex-col items-center min-h-screen bg-gray-100">
      <Box
        className="flex flex-col items-center w-full max-w-md p-4 bg-white flex-grow overflow-auto"
        sx={{ paddingBottom: "80px" }}
      >
        <Box className="flex items-center mb-4 w-full">
          <Avatar
            alt={user.name}
            src={
              profileImageBinary
                ? `data:image/jpeg;base64,${profileImageBinary}`
                : "/static/images/avatar/1.jpg"
            }
            sx={{ width: 56, height: 56 }}
          />
          <Box className="ml-4">
            <Typography
              variant=""
              className="font-bold text- l"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            >
              Hello, Welcome back
            </Typography>
            <Typography
              className="text-sm"
              sx={{ fontFamily: "Poppins, Arial, sans-serif" }}
            >
              {user.name}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "100%", maxWidth: 600, marginBottom: 4 }}>
          <Carousel indicators={true} navButtonsAlwaysInvisible={true} animation="slide">
            <CarouselImage component="img" src={soft} alt="merdeka" sx={{ objectFit: "cover" }} />
            <CarouselImage component="img" src={tocatim} alt="backdrop" sx={{ objectFit: "cover" }} />
          </Carousel>
        </Box>

        <Box className="mb-4 w-full">
          <Typography variant="h7" className="font-bold">
            DASHBOARD
          </Typography>
        </Box>

        <Grid container spacing={2} justifyContent="space-between" className="mb-4">
          <Grid item xs={3}>
            <Box className="text-center" sx={{ cursor: "pointer" }} onClick={(event) => handleNavigation(event, 4)}>
              <ShowChartIcon sx={{ fontSize: 40, color: "#0FA958" }} />
              <Typography variant="body1" sx={{ fontSize: "0.850rem", fontFamily: "Poppins, Arial, sans-serif" }}>
                Personal Score
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="text-center" onClick={(event) => handleNavigation(event, 5)}>
              <LibraryBooksIcon sx={{ fontSize: 40, color: "#E31B1E" }} />
              <Typography variant="body1" sx={{ fontSize: "0.850rem", fontFamily: "Poppins, Arial, sans-serif" }}>
                Assessor
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className="text-center" onClick={(event) => handleNavigation(event, 7)}>
              <DashboardIcon sx={{ fontSize: 40, color: "#FEB800" }} />
              <Typography variant="body1" sx={{ fontSize: "0.875rem", fontFamily: "Poppins, Arial, sans-serif" }}>
                Mandatory
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box className="mb-4 w-full mt-4">
          <Typography variant="h7" className="font-bold">
            RECOMMENDATION
          </Typography>
          <Typography variant="body1">ykbut learning center podcast</Typography>
        </Box>
        <div className="podcast">
          <StyledCard className="w-full" sx={{ backgroundColor: "#F6F6F6", boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)" }} onClick={() => handleCardClick("/Description")}>
            <StyledCardMedia image={pod1} title="Podcast 1" />
            <StyledCardContent>
              <Typography variant="body1" className="font-semibold">
                Podcast Corner #14
              </Typography>
              <p className="text-sm">Building teamwork with a clear framework</p>
            </StyledCardContent>
          </StyledCard>

          <StyledCard className="w-full" sx={{ backgroundColor: "#F6F6F6", boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)" }} onClick={() => handleCardClick("/Description")}>
            <StyledCardMedia image={pod2} title="Podcast 2" />
            <StyledCardContent>
              <Typography variant="body1" className="font-semibold">
                Podcast Corner #15
              </Typography>
              <p className="text-sm">How to grow from your challenges</p>
            </StyledCardContent>
          </StyledCard>

          <StyledCard className="w-full" sx={{ backgroundColor: "#F6F6F6", boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)" }} onClick={() => handleCardClick("/Description")}>
            <StyledCardMedia image={pod3} title="Podcast 3" />
            <StyledCardContent>
              <Typography variant="body1" className="font-semibold">
                Podcast Corner #16
              </Typography>
              <p className="text-sm">How to excel in high-pressure situations</p>
            </StyledCardContent>
          </StyledCard>
        </div>
      </Box>

      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto">
        <BottomNavigation
          value={value}
          onChange={handleNavigation}
          showLabels
          sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            sx={{
              color: value === 0 ? 'blue' : 'inherit', // Change color when active
            }}
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            sx={{
              color: value === 1 ? 'blue' : 'inherit', // Change color when active
            }}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            sx={{
              color: value === 2 ? 'blue' : 'inherit', // Change color when active
            }}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            sx={{
              color: value === 3 ? 'blue' : 'inherit', // Change color when active
            }}
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
};

export default HomePage;
